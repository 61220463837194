<template>
  <div class="text-container">
    <div>
      <p>
        Durch vorausschauendes und rechtzeitiges Handeln sollen die ersten
        Anzeichen einer plötzlichen finanziellen Schieflage erkannt werden. Dazu
        gehört:
      </p>
      <ul>
        <li>Kreditlinie nicht dauerhaft ausschöpfen bzw. überziehen</li>
        <li>Auf Störungen innerbetrieblicher Abläufe sofort reagieren</li>
        <li>Gemeinsame Ursachenanalyse und Erstellung einer Maßnahmenliste</li>
      </ul>
      <div>Wir unterstützen Sie hierbei in den folgenden Bereichen:</div>
    </div>
    <div class="pt-2">
      <div
        v-for="service in getChildsOfSite('betriebswirtschaft')"
        :key="service.name"
        class="my-1"
      >
        <a :href="getSitePath(service.name)" class="service-link">
          <b-icon :icon="service.icon" class="mr-3"></b-icon>
          <span>{{ service.label.replace("[shy]", "") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { getChildsOfSite, getSitePath } from "../../config/site-structure";

export default {
  methods: { getChildsOfSite, getSitePath },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie im betriebswirtschaftlichen Bereichen zu Ihrer Apotheke.",
      },
    ],
  },
};
</script>

<style scoped>
.service-link {
  color: #2c3e50;
}
</style>